@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  padding: 1%;
  width: 14%;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;

  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-content a:hover {
  background-color: inherit;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.MuiPaper-root ::before {
  background-color: white;
}

.MuiAccordionroot {
  background-color: "white";
  box-shadow: 0;
}
.react-calendar {
  background: #212529;
  color: white;
  border: none;
  font-size: 16px;
  padding: 20px 15px;
  width: 100%;
  max-width: 100%;
  width: 100%;
  height: 450px;
}
.react-calendar__navigation__label__labelText {
  padding: 20px;
}
.react-calendar__navigation {
  padding: 10px;
  text-align: center;
}
.react-calendar__navigation__arrow {
  padding: 10px;
  font-size: 30px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 20px;
}

.react-calendar__tile abbr:hover {
  background: #f3f3f3;
  color: #212529;
}
.react-calendar__tile {
  width: 45px;
  height: 45px;
  padding: 5px;
}
.react-calendar__tile abbr {
  padding: 12px 15px;
  border-radius: 50px;
}

.react-calendar__tile--active abbr {
  background: white;
  color: #212529;
}

.react-calendar__tile:disabled abbr {
  background: transparent;
  color: #5e5e5e;
}
::-webkit-scrollbar {
  display: none;
}
